<template>
  <v-container>
    <draggable
      v-model="automationList"
      group="automation"
      @start="drag=true"
      @end="drag=false"
      handle=".handle"
    >
      <v-row v-for="(object,i) in automationList" :key="i" no-gutters>
        <v-col class="d-flex">
          <div class="col-auto" >
            <v-icon class="handle">mdi-drag-vertical</v-icon>
            <trash @click="removeSeleniumCmdList(i)"></trash>
          </div>
          <associatedOption
            type="select"
            :fieldAttrInput="{}"
            :fieldAttributes="{'name':'Command',associatedOption:  result.additional.seleniumCommands}"
            v-model="object.command"
            @input="updateObject(i,'command',...arguments)"
          ></associatedOption>
        </v-col>
        <v-col>
          <primaryTextfield
            v-if="typeof object.subCommand1!='undefined'"
            type="text"
            name="cmdValue1"
            :label="getPlaceholder(object.command,'cmdValue1')"
            counter
            v-model="object.subCommand1"
            :fieldAttrInput="{}"
            @input="updateObject(i,'cmdValue1',...arguments)"
          ></primaryTextfield>
        </v-col>
        <v-col>
          <primaryTextfield
            v-if="typeof object.subCommand2!='undefined'"
            type="text"
            name="cmdValue2"
            :label="getPlaceholder(object.command,'cmdValue2')"
            counter
            v-model="object.subCommand2"
            @input="updateObject(i,'subCommand2',...arguments)"
            :fieldAttrInput="{}"
          ></primaryTextfield>
        </v-col>
      </v-row>
    </draggable>

    <primaryButton label="Add Action" @click="addSeleniumCmdList();" type="button" color="primary"></primaryButton>

    <div
      v-if="fieldAttributes.seleniumBox === true"
      class="superAdminCmdShow editableDiv ui-sortable"
    >
      <div v-for="(object,i) in visibleList" :key="i">{{object}}</div>
    </div>
  </v-container>
</template>
<script>
import draggable from "vuedraggable";
import primaryButton from "@/commonComponents/primaryButton.vue";
import primaryTextfield from "@/commonComponents/primaryTextfield.vue";
import associatedOption from "@/commonComponents/associatedOption.vue";
import trash from "@/commonComponents/dynamic/trash.vue";
// import { getfieldAttrInput } from "@/js/helper.js";
export default {
  components: {
    primaryTextfield,
    associatedOption,
    draggable,
    primaryButton,
    trash,
  },
  data() {
    return {
      placeholder: {
        open: { cmdValue1: " Enter the URL" },

        rtiTextOut: { cmdValue1: " Write the Comments" },
        type: {
          cmdValue1: " Xpath",
          cmdValue2: " Text",
        },
        typePassword: {
          cmdValue1: " Xpath",
          cmdValue2: " Password",
        },
        clickAndWait: {
          cmdValue1: " Xpath",
        },
        rtiGetText: {
          cmdValue1: " Xpath",
          cmdValue2: " Options",
        },
        rtiPause: {
          cmdValue1: " time in secounds",
        },
      },
    };
  },
  props: ["field", "fieldAttributes", "result","value"],
  computed: {
    // fieldAttrInput() {
    //   return getfieldAttrInput(this.fieldAttributes);
    // },

    automationList: {
      get() {
      
        var newCmdList = {};
        var finalList = [];
        let givenValue = this.value;
        //givenValue = givenValue.replace(/(\r\n|\n|\r)/gm, ""); // Remove new line /enter /tab so on
        if (typeof givenValue != "undefined") {
          var row = givenValue.split(/(\r\n|\n|\r)/gm);
          row.forEach((source) => {
            source = source.replace(/xpath=/g, ""); // Remove xpath=
            source = source.replace(/\|$/, ""); // Remove if it have | on LAST
            source = source.replace(/\\/g, "");
            source = source.replace(/(\r\n|\n|\r)/gm, ""); // Remove new line /enter /tab so on
            if (source != "") {
              var cmdSplit = source.split("|");

              newCmdList = {
                command: cmdSplit[0],
              };
              if (typeof cmdSplit[1] != "undefined") {
                newCmdList.subCommand1 = cmdSplit[1];
              }
              if (typeof cmdSplit[2] != "undefined") {
                newCmdList.subCommand2 = cmdSplit[2];
              }
              //console.log(newCmdList);
              finalList.push(newCmdList);
            }
          });
        }
        //console.log(finalList);
        return finalList;
      },
      set(automationCommandList) {
        this.updateAutomation(automationCommandList);
      },
    },
    visibleList() {
      let givenValue = this.value;
      let returnedArray = [];
      if (typeof givenValue != "undefined") {
        var row = givenValue.split(/(\r\n|\n|\r)/gm);
        row.forEach((source) => {
          source = source.replace(/(\r\n|\n|\r)/gm, ""); // Remove new line /enter /tab so on
          returnedArray.push(source);
        });
      }
      return returnedArray;
    },
  },
  methods: {
    getPlaceholder(command, cmdValue1) {
      if (typeof this.placeholder[command] != "undefined") {
        return this.placeholder[command][cmdValue1];
      }
    },
    updateAutomation(automationCommandList) {
      //console.log("setter called");
      let command = "";
      let $connector = "|";
      let $stream = "";
      let $xPath = "xpath=";
      let $subCommand1 = "";
      let $subCommand2 = "";
      //console.log(automationCommandList);
      automationCommandList.forEach(function (automationCommand) {
        if (typeof automationCommand != "undefined") {
          if (typeof automationCommand["subCommand1"] == "undefined") {
            automationCommand["subCommand1"] = "";
          }
          if (typeof automationCommand["subCommand2"] == "undefined") {
            automationCommand["subCommand2"] = "";
          }
          if (automationCommand["command"] == "open") {
            $subCommand1 = automationCommand["subCommand1"];
            $stream += $connector + $subCommand1;
          } else if (automationCommand["command"] == "rtiTextOut") {
            $subCommand1 = automationCommand["subCommand1"];
            $stream += $connector + $subCommand1;
          } else if (automationCommand["command"] == "type") {
            $subCommand1 = $xPath + automationCommand["subCommand1"];
            $subCommand2 = automationCommand["subCommand2"];
            $stream += $connector + $subCommand1 + $connector + $subCommand2;
          } else if (automationCommand["command"] == "typePassword") {
            $subCommand1 = $xPath + automationCommand["subCommand1"];
            $subCommand2 = automationCommand["subCommand2"];
            $stream += $connector + $subCommand1 + $connector + $subCommand2;
          } else if (automationCommand["command"] == "clickAndWait") {
            $subCommand1 = $xPath + automationCommand["subCommand1"];
            $stream += $connector + $subCommand1;
          } else if (automationCommand["command"] == "rtiGetText") {
            $subCommand1 = $xPath + automationCommand["subCommand1"];
            $subCommand2 = automationCommand["subCommand2"];
            $stream += $connector + $subCommand1 + $connector + $subCommand2;
          } else if (automationCommand["command"] == "rtiPause") {
            $subCommand1 = automationCommand["subCommand1"];
            $stream += $connector + $subCommand1;
          } else {
            //$connector="|\n";
          }
          command += automationCommand["command"] + $stream + $connector + "\n";
        }
        $stream = "";
      });

      this.$emit("input", command);
    },
    updateObject(i, index, value) {
      let automationCommandList = [...this.automationList];
      //console.log(i, index, value);
      automationCommandList[i][index] = value;
      this.updateAutomation(automationCommandList);
    },
    removeSeleniumCmdList(i) {
      //console.log(this.automationList);
      let automationCommandList = [...this.automationList];

      automationCommandList.splice(i, 1);
      this.updateAutomation(automationCommandList);
    },
    addSeleniumCmdList() {
      //console.log(this.automationList);
      let automationCommandList = [...this.automationList];

      automationCommandList.push({});
      this.updateAutomation(automationCommandList);
    },
  },
};
</script>